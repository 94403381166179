import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import config from '../utils/siteConfig'
import { StaticQuery, graphql } from "gatsby"

const SEO = (props) => {

    return (
    <StaticQuery
    query={graphql`
      query GlobalVars {
        allContentfulGlobalVariables(filter: {id: {eq: "edec6887-d846-5e51-b03f-0a80904aa7d5"}}) {
          edges {
            node {
              facebookId
              facebookUrl
              twitterHandle
              twitterUrl
              instagramUrl
              phoneNumber
              faxNumber
              mainEmailAddress
              officeAddress {
                childMarkdownRemark {
                  html
                }
              }
              id
            }
          }
        }
      }
    `}
    render={data => {

      const {node: contentfulConfig} = data.allContentfulGlobalVariables.edges[0]
      const { postNode, pagePath, postSEO, pageSEO, customTitle } = props
      let title
      let description
      let keywords
      let image
      let imgWidth
      let imgHeight
      let pageUrl
      let facebookId
      let twitterHandle

      // Set Default OpenGraph Parameters for Fallback
      title = config.siteTitle
      description = config.siteDescription
      keywords = config.siteKeywords
      image = config.siteUrl + config.shareImage
      imgWidth = config.shareImageWidth
      imgHeight = config.shareImageHeight
      pageUrl = config.siteUrl
      facebookId = contentfulConfig.facebookId
      twitterHandle = contentfulConfig.twitterHandle

      if (customTitle) {
        title = postNode.pageTitle
        pageUrl = config.siteUrl + '/' + pagePath + '/'
      }

      // Replace with Page Parameters if post or page
      if (postSEO || pageSEO) {
        title = postNode.pageTitle
        description =
          postNode.metaDescription === null
            ? description
            : postNode.metaDescription.metaDescription
        keywords =
          postNode.metaKeywords === null
            ? keywords
            : postNode.metaKeywords.metaKeywords

        pageUrl = config.siteUrl + '/' + pagePath + '/'
      }
      // Use Hero Image for OpenGraph
      
      if (postNode.shareImage) {
        image = 'https:' + postNode.shareImage.ogimg.src
        imgWidth = postNode.shareImage.ogimg.width
        imgHeight = postNode.shareImage.ogimg.height
      }

      // Default Website Schema
      const schemaOrgJSONLD = [
        {
          '@context': 'http://schema.org',
          '@type': 'WebSite',
          url: config.siteUrl,
          name: config.siteTitle,
          alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
        },
      ]

      // Blog Post Schema
      if (postSEO) {
        schemaOrgJSONLD.push(
          {
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                item: {
                  '@id': config.siteUrl,
                  name: config.siteTitle,
                },
              },
              {
                '@type': 'ListItem',
                position: 2,
                item: {
                  '@id': pageUrl,
                  name: title,
                },
              },
            ],
          },
          {
            '@context': 'http://schema.org',
            '@type': 'BlogPosting',
            url: pageUrl,
            name: title,
            alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
            headline: title,
            image: {
              '@type': 'ImageObject',
              url: image,
              width: imgWidth,
              height: imgHeight,
            },
            author: {
              '@type': 'Person',
              name: config.author,
              url: config.authorUrl,
            },
            publisher: {
              '@type': 'Organization',
              name: config.publisher,
              url: config.siteUrl,
            },
            datePublished: postNode.publishDateISO,
            mainEntityOfPage: pageUrl,
          }
        )
      }

      // Page SEO Schema
      if (pageSEO) {
        schemaOrgJSONLD.push({
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          url: pageUrl,
          name: title,
        })
      }
      
      return (
        <Helmet>
          {/* General tags */}
          <meta name="image" content={image} />
          <meta name="description" content={description} />
          <meta name="keywords" content={keywords} />

          {/* Schema.org tags */}
          <script type="application/ld+json">
            {JSON.stringify(schemaOrgJSONLD)}
          </script>

          {/* OpenGraph tags */}
          <meta property="og:title" content={title} />
          {postSEO ? <meta property="og:type" content="article" /> : <meta property="og:type" content="website" />}
          {facebookId && facebookId !== " " ? <meta property="fb:app_id" content={facebookId} />  : ''}

          <meta property="og:url" content={pageUrl} />
          <meta property="og:image" content={image} />
          <meta property="og:image:width" content={imgWidth} />
          <meta property="og:image:height" content={imgHeight} />
          <meta property="og:description" content={description} />

          {/* Twitter Card tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:creator"
            content={twitterHandle && twitterHandle !== " " || ''}
          />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:image" content={image} />
          <meta name="twitter:description" content={description} />
        </Helmet>
      )
    }}
  />
  )
}

export default SEO